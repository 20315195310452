// css
import "./index.css";

import {
  generateIntegrationUrl,
  getStorage,
  listenForCommonRequests,
  setErxesProperty
} from "../../widgetUtils";

declare const window: any;

/*
 * Messenger message's embeddable script
 */

// check is mobile
const isMobile =
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/Android/i);

let viewportMeta: any;
let newViewportMeta: any;
let hideDelayTimer: any;

const delay = 350;

if (isMobile) {
  viewportMeta = document.querySelector('meta[name="viewport"]');
}

function renewViewPort() {
  if (viewportMeta) {
    document.getElementsByTagName("head")[0].removeChild(viewportMeta);
  }

  newViewportMeta = document.createElement("meta");
  newViewportMeta.name = "viewport";
  newViewportMeta.content =
    "initial-scale=1, user-scalable=0, maximum-scale=1, width=device-width";
  document.getElementsByTagName("head")[0].appendChild(newViewportMeta);
}

function revertViewPort() {
  if (newViewportMeta) {
    document.getElementsByTagName("head")[0].removeChild(newViewportMeta);
  }

  if (viewportMeta) {
    document.getElementsByTagName("head")[0].appendChild(viewportMeta);
  }
}

function delaydToggleClass(str: string, isVisible: boolean) {
  hideDelayTimer = setTimeout(() => {
    salesmatchContainer.classList.toggle(str, isVisible);
  }, delay);
}

function delaydSetClass(str: string) {
  hideDelayTimer = setTimeout(() => {
    salesmatchContainer.classList.remove("salesmatch-messenger-hidden", "salesmatch-messenger-shown");
    salesmatchContainer.className = str;
  }, delay);
}

function clearTimer() {
  if (hideDelayTimer) {
    clearTimeout(hideDelayTimer);
  }
}

const setting = (window.salesmatchSettings) ? window.salesmatchSettings.messenger : window.erxesSettings.messenger || [];
const isEmbed = setting?.is_embed

// container
// using "salesmatch-messenger-...-embed" instead of just "embed" to prevent style issues on parent document styles
const containerId = "salesmatch-messenger-container";
const containerClassName = isEmbed ? "salesmatch-messenger-container-embed" : ""
const salesmatchContainer = document.createElement("div");
salesmatchContainer.id = containerId;
salesmatchContainer.className = "salesmatch-messenger-hidden" + " " + containerClassName;

// add iframe
const iframeId = "salesmatch-messenger-iframe";
const iframeClassName = isEmbed ? "salesmatch-messenger-iframe-embed" : "";
const iframe: any = document.createElement("iframe");

iframe.id = iframeId;
iframe.className = iframeClassName;
iframe.src = generateIntegrationUrl("messenger");
iframe.style.display = "none";
iframe.allow = "camera *;microphone *";

salesmatchContainer.appendChild(iframe);


const embedContainer = document.querySelector("[data-salesmatch-messenger-embed=\"" + setting.brand_id + "\"]");
if (embedContainer) {
  embedContainer.appendChild(salesmatchContainer);
} else {
  document.body.appendChild(salesmatchContainer);
}


// after iframe load send connection info
iframe.onload = async () => {
  iframe.style.display = "block";

  const contentWindow = iframe.contentWindow;

  if (!contentWindow) {
    return;
  }

  const search = window.location?.search || ''
  const searchParams = new URLSearchParams(search)

  const customerId = searchParams.get('salesmatch.ai')
  const origin = searchParams.get('origin')
  const IS_LEAD = Boolean(customerId);
  setting["IS_LEAD"] = IS_LEAD
  if (setting.customerId) localStorage.setItem("customerId", setting.customerId)
  
  if (customerId) {
    setting["customerId"] = customerId
    localStorage.setItem("customerId", customerId)
  }
  if(origin) setting["origin"] = origin
  else setting["origin"] = document?.referrer || window.location?.host

  setErxesProperty("showMessenger", () => {
    contentWindow.postMessage(
      {
        fromPublisher: true,
        action: "showMessenger"
      },
      "*"
    );
  });

  contentWindow.postMessage(
    {
      fromPublisher: true,
      setting,
      storage: getStorage()
    },
    "*"
  );
};

// listen for widget toggle
window.addEventListener("message", async (event: MessageEvent) => {
  const data = event.data;
  const { isVisible, message, isSmallContainer } = data;

  listenForCommonRequests(event, iframe);

  if (data.fromErxes && data.source === "fromMessenger") {
    if (isMobile) {
      document.body.classList.toggle("widget-mobile", isVisible);
    }

    if (message === "messenger") {
      if (isMobile && isVisible) {
        renewViewPort();
      } else {
        revertViewPort();
      }

      clearTimer();

      if (isVisible) {
        salesmatchContainer.classList.remove("salesmatch-messenger-hidden");
        salesmatchContainer.classList.add("salesmatch-messenger-shown");
      } else {
        delaydSetClass("salesmatch-messenger-hidden");
      }

      salesmatchContainer.classList.toggle("small", isSmallContainer);
      document.body.classList.toggle("messenger-widget-shown", isVisible);
    }

    if (message === "notifier") {
      clearTimer();
      delaydToggleClass("salesmatch-notifier-shown", isVisible);

      // change container div dimension
      if (!isVisible) {
        delaydSetClass("salesmatch-messenger-hidden");
      }
    }

    if (message === "notifierFull") {
      clearTimer();

      // add class and hide notifier
      if (isVisible) {
        salesmatchContainer.className += " salesmatch-notifier-shown fullMessage";
      } else {
        delaydSetClass("salesmatch-messenger-hidden");
      }
    }
  }
});
